import axios from 'axios';
import LoginSign from '../components/Auth/loginSignPage/loginSign';

// Comment/Uncomment the baseURL and baseURL(Heroku) between test and prod
// TEST
export const baseURL = 'https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx';
export const redirectURL = 'https://mobapp.rcuae.ae/MOBAPP_V3';

// PROD
// export const baseURL =
//   "https://mobapp.rcuae.ae/MOBAPP_V3_PROD/ERC_MOB_V2_WS.asmx";
// export const redirectURL = "https://mobapp.rcuae.ae/MOBAPP_V3_PROD";

export const APIForVolunteerV3V2 = axios.create({
  baseURL: `${baseURL}/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getSukuk = (langNumber, device_type) =>
  axios.get(
    `${baseURL}/sukuk_list?language=${langNumber}&device_type=${device_type}`
  );

export const getGroupPDF = (langNumber) =>
  axios.get(`${baseURL}/volunteer_groups_pdf?language=${langNumber}`);

export const volunteerSuccessRegMessage = (langNumber, volunteerID) =>
  axios.get(
    `${baseURL}/volunteer_thank_you?language=${langNumber}&volunteerID=${volunteerID}`
  );

//---------------------------------------------------------------------
// export const getProjects = (langNumber, orderType, orderBy, maxVal, minValu, projTypeID) =>
//   axios.get(
//     `${baseURL}/get_project_types?orderType=${orderType}&orderBy=${orderBy}&minVal=${minValu}&maxVal=${maxVal}&language=${langNumber}&projTypeID=${projTypeID}`
//   );

// export const getCountriesForProject = (
//   langNumber,
//   projectTypeID,
//   orderType,
//   orderBy,
//   maxVal,
//   minVal,

// ) =>
//   axios.get(
//     `${baseURL}/get_project_countries_same_project?orderType=${orderType}&orderBy=${orderBy}&minVal=${minVal}&maxVal=${maxVal}&language=${langNumber}&projectTypeID=${projectTypeID}`
//   );

// export const getProjectsFinal = (
//   langNumber,
//   projectTypeID,
//   countryID,
//   orderType,
//   orderBy,
//   maxVal,
//   minVal,
//   projTypeID
// ) =>
//   axios.get(
//     `${baseURL}/get_allprojects_web?orderType=${orderType}&orderBy=${orderBy}&minVal=${minVal}&maxVal=${maxVal}&language=${langNumber}&projectTypeID=${projectTypeID}&countryID=${countryID}`
//   );

// export const getCountriesForProjects = (
//   langNumber,
//   orderType,
//   orderBy,
//   maxVal,
//   minVal,
//   projTypeID
// ) =>
//   axios.get(
//     `${baseURL}/get_project_countries?orderType=${orderType}&orderBy=${orderBy}&minVal=${minVal}&maxVal=${maxVal}&language=${langNumber}&projTypeID=${projTypeID}`
//   );

// export const getProjectsForCountries = (
//   langNumber,
//   countryID,
//   orderType,
//   orderBy,
//   maxVal,
//   minVal,
//   projTypeID
// ) =>
//   axios.get(
//     `${baseURL}/get_project_types_from_country?orderType=${orderType}&orderBy=${orderBy}&minVal=${minVal}&maxVal=${maxVal}&language=${langNumber}&countryID=${countryID}&projTypeID=${projTypeID}`
//   );

export const getFullProjects = (langNumber) =>
  axios.get(`${baseURL}/allprojects?language=${langNumber}`);

//-------------------Set orphans amount-----------------------------------------------
export const setOrphansAmount = (sponID, orphanID, newAmount, lang) =>
  axios.get(
    `${baseURL}/update_orphan_payment?sponsor_id=${sponID}&orphan_id=${orphanID}&amount=${newAmount}&language=${lang}`
  );
export const getAidConstraint = () =>
  axios.get(`${baseURL}/aid_upload_constraints`);

export const setOrphansPaymentCard = (
  email,
  sponsor_id,
  orphan_id,
  new_card,
  lang
) =>
  axios.get(
    `${baseURL}/update_orphan_card?email=${email}&sponsor_id=${sponsor_id}&orphan_id=${orphan_id}&new_card=${new_card}&device_id=WEBAPP&language=${lang}`
  );

export const setOrphansPaymentCardAll = async (
  email,
  sponsor_id,
  orphan_id,
  new_card,
  device_id,
  lang
) =>
  await axios({
    method: 'GET',
    url:
      `${baseURL}/update_orphan_card_all?` +
      new URLSearchParams({
        email: email,
        sponsor_id: sponsor_id,
        new_card: new_card,
        device_id: device_id,
        language: lang
      }) +
      orphan_id.map((item) => '&orphan_id=' + item).join(''),
    mode: 'no-cors'
  });
//-------------------Contact us apis--------------------------------------------------

// export const contactUsLookup = () => axios.post(`https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/contact_us_lookup`);
export const contactUsLookup = (langNumber) =>
  axios.get(`${baseURL}/contact_us_lookup?language=${langNumber}`);

// export const contactUs = (formData) => axios.post(`https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/contact_us`, formData);
export const contactUs = (query) => axios.get(`${baseURL}/contact_us${query}`);

//---------------------------------------------------------------------

export const getOrphanSliders = (langString) =>
  axios.get(`${baseURL}/orphan_slider?P_LANG=${langString}`);

//---------------------------------------------------------------------

// --------Add card api--------
export const addCardApi = (query) => axios.get(`${baseURL}/add_card${query}`);

//tokenize api
// Done
export const tokenizeCard = (expMonth, expYear, cardNumber, securityNumber) =>
  axios.post(`${baseURL}/tokenize_card`, {
    card: {
      number: cardNumber,
      expiryMonth: expMonth,
      expiryYear: expYear,
      cvc: securityNumber
    }
  });

//delete card
export const deleteCard = (email, token) =>
  axios.get(`${baseURL}/delete_paymentmethod?email=${email}&token=${token}`);
//get cards
export const getPaymentMethod = (email) =>
  axios.get(`${baseURL}/get_cards?email=${email}`);
export const getDonations = (lang, device_type) =>
  axios.get(
    `${baseURL}/donation_list?language=${lang}&device_type=${device_type}`
  );

//---------------------------------------------------------------------

export const getCompaginsSlider = (langNumber, device_type) =>
  axios.get(
    `${baseURL}/get_campaigns?language=${langNumber}&device_type=${device_type}`
  );

//---------------------------------------------------------------------

export const getHumanCases = (langNumber) =>
  axios.get(`${baseURL}/human_cases?language=${langNumber}`);

export const getProjectShares = (langNumber) =>
  axios.get(`${baseURL}/project_sharing_list_active?language=${langNumber}`);

//---------------------------------------------------------------------

export const signUp = (options) =>
  axios.get(
    `${baseURL}/creatacc2?login_type=${
      options.login_type +
      '&passwrd=' +
      options.passwrd +
      '&email=' +
      options.email +
      '&mobile_no=' +
      options.mobile_no +
      '&uid_no=' +
      options.uid_no +
      '&usr_name=' +
      options.usr_name
    }`
  );
export const signIn = async (options) => {
  let response = await axios({
    method: 'POST',
    url: `${baseURL}/user_login_with_password`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    data: new URLSearchParams({
      email: options.user_id,
      password: options.password
    })
  });
  const jwtToken = response.data.data;
  if (!jwtToken) throw new Error('Invalid username or password');
  localStorage.setItem('access_token', jwtToken);
  return await whoAmI();
};

export const whoAmI = async () => {
  let jwtToken = localStorage.getItem('access_token');
  axios.defaults.headers.common.Authorization = jwtToken ?? '';

  let respnse = await axios({
    method: 'GET',
    url: `${baseURL}/whoAmI`,
    headers: {
      token: jwtToken
    }
  });
  return respnse.data.data;
};

export const emailChecker = (email) =>
  axios.get(`${baseURL}/check_acc?user_name=${email}`);
export const otpVerfication = (userData) =>
  //"&otp_mob=" +userData.otpMob +
  axios.get(
    `${baseURL}/vldnwacc2?email=${
      userData.email +
      '&otp=' +
      userData.otpEmail +
      '&vln_id=' +
      userData.vln_id +
      '&persn_id=' +
      userData.persn_id +
      '&sponser_id=' +
      userData.sponser_id
    }`
  );
export const updateNumber = async (userData) =>
  await axios.get(
    `${baseURL}/update_number?new_number=${
      userData.new_number + '&user_email=' + userData.user_email
    }`
  );
export const confirmPhoneOtp = async (userData) =>
  await axios.get(
    `${baseURL}/confirm_phone_otp?new_number=${
      userData.new_number +
      '&user_email=' +
      userData.user_email +
      '&otp=' +
      userData.otp
    }`
  );
export const reSendOTP = (email) =>
  axios.get(`${baseURL}/resend_otp?email=${email}`);
export const resetPassReq = (userData) =>
  axios.get(
    `${baseURL}/reset_pass?user_id=${
      userData.email + '&otp_type=' + userData.otp_type
    }`
  );
export const resetPassOTP = (userData) =>
  axios.get(
    `${baseURL}/forget_pass_otp?email=${
      userData.email + '&otp=' + userData.otp
    }`
  );
export const updatePassword = (userData) =>
  axios.get(
    `${baseURL}/update_password?email=${
      userData.email + '&newPassword=' + userData.newPassword
    }`
  );
export const gettingTheAccount = (userID) =>
  axios.get(`${baseURL}/forgot_account?userID=${userID}`);

export const changeUserPassword = (userID, newPassword) =>
  axios.get(
    `${baseURL}/update_password_logged_in?email=${userID}&newPassword=${newPassword}`
  );
export const sponsorGetOtp = (sponsorID) =>
  axios.get(`${baseURL}/sponsor_login_otp?sponsor_id=${sponsorID}`);
export const sponsorVerifyOtp = (sponsorID, OTP) =>
  axios.get(
    `${baseURL}/sponsor_login_otp_verify?sponsor_id=${sponsorID}&otp=${OTP}`
  );
export const updateSponsorEmail = (sponsorID, OTP, email) =>
  axios.get(
    `${baseURL}/sponsor_update_email_and_link?sponsor_id=${sponsorID}&otp=${OTP}&email=${email}`
  );

//---------------------------------------------------------------------

export const getOrphans = (langNumber) =>
  axios.get(`${baseURL}/allorphan_available?language=${langNumber}`);

export const getEventsTracker = (langNumber) =>
  axios.get(`${baseURL}/events_timeline?language=${langNumber}`);

export const getTutorials = (langNumber) =>
  axios.get(`${baseURL}/tutorial_screens?language=${langNumber}`);

//---------------------------------------------------------------------

export const regularPayment = (regularPaymentBody) =>
  axios({
    method: 'POST',
    url: `${baseURL}/init_pay_web`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      MCO: '',
      payment_type: regularPaymentBody.payment_type,
      device_type: 'WEBAPP',
      tokn: regularPaymentBody.tokn,
      bag: regularPaymentBody.bag,
      captcha: regularPaymentBody?.captcha ?? ''
    }
  });
export const confirmPayment = (body) =>
  axios({
    method: 'POST',
    url: `${baseURL}/update_cart`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: new URLSearchParams({ MCO: body.MCO, MCToken: body.token })
  });
export const validateApplePay = async (url, location) => {
  var res = await axios.get(
    `${baseURL}/validate_apple?validationURL=${url}&location=${location}`
  );
  return res.data;
};
export const validateSamsungPay = async (body) =>
  await axios({
    method: 'POST',
    url: `${baseURL}/validate_samsung`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: new URLSearchParams({ amount: body.amount, callback: body.url })
  });
export const addExtraSponsors = async (data) =>
  await axios({
    method: 'POST',
    url: `${baseURL}/add_sponsors_to_account`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  });
export const getSamsungPay = async (body) =>
  await axios({
    method: 'POST',
    url: `${baseURL}/get_samsung_token`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: new URLSearchParams({ ref_id: body })
  });
export const vulunteerCounts = (userData) =>
  axios({
    method: 'POST',
    url: `${baseURL}/volunteer_participations`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
// export const randomNumber = () =>
//   axios.get("https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/get_mco");

//---------------------------------------------------------------------

export const getCourses = (langNumber) =>
  axios.get(`${baseURL}/course_lookup?language=${langNumber}`);
// TODO should not be static countries?
export const getCountriesForVolun = (langNumber) =>
  axios.get(`${baseURL}/country_lookup?language=${langNumber}`);

//---------------------------------------------------------------------

export const getRelatives = (langString, pUserID) =>
  axios.get(
    `${baseURL}/local_aids_family_members?p_user_id=${
      pUserID + '&Lang=' + langString
    }`
  );
export const getLocalAidProfile = (langString, pUserID) =>
  axios.get(
    `${baseURL}/local_aids_file_info?p_user_id=${
      pUserID + '&Lang=' + langString
    }`
  );
export const getLocalAidCompletedRequ = (langString, pUserID) =>
  axios.get(
    `${baseURL}/local_aids_old_aids_details?p_user_id=${
      pUserID + '&Lang=' + langString
    }`
  );
export const getLocalAidReturnedRequ = (langString, pUserID) =>
  axios.get(
    `${baseURL}/UserLocalAidsRequestsReturned?user_id=${
      pUserID + '&Lang=' + langString
    }`
  );
export const getLocalAidPendingRequ = (langString, UserID) =>
  axios.get(
    `${baseURL}/UserLocalAidsRequestsPending?user_id=${
      UserID + '&Lang=' + langString
    }`
  );
export const getLocalAidTypes = (langString, PUserID, pRequYear) =>
  axios.get(
    `${baseURL}/AIDSTYPE_ALL?P_PERSON_ID=${
      PUserID + '&Lang=' + langString + '&P_REQ_YEAR=' + pRequYear
    }`
  );
//insertProfileformData---Register For Local AID---

//---------------------------------------------------------------------
export const getVolunteerDetails = (volunteerID, langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_details?volunteerID=${volunteerID}&language=${langNumber}`
  );
// Using MOBAPP_V3 and ERC_MOB_V3
export const getVolunteerActiveEvents = (
  volunteerID,
  langNumber,
  EmirtaesId,
  email
) =>
  APIForVolunteerV3V2.get(
    `get_active_volunteer_events?page=1&language=${langNumber}&volunteerId=${volunteerID}&nationalId=${EmirtaesId}&email=${email}`
  );

export const getTotalHourSpentAsVolunteer = (volunteerID, langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_participations?volunteerID=${volunteerID}&language=${langNumber}`
  );
export const getVolunteerAndAcademicDetails = (volunteerID) =>
  APIForVolunteerV3V2.get(`REQUEST_FORM_DATA?vln_id=${volunteerID}`);
export const getVolunteerCourses = (volunteerID, langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_courses?volunteerID=${volunteerID}&language=${langNumber}`
  );
export const getCourseLookup = (langNumber) =>
  APIForVolunteerV3V2.get(`course_lookup?language=${langNumber}`);
export const getCountryLookup = (langNumber) =>
  APIForVolunteerV3V2.get(`country_lookup?language=${langNumber}`);
export const getEduQualLookUp = (langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_education_all_lookup?language=${langNumber}`
  );
export const addVolunteerCourse = (
  volunteerID,
  courseID,
  trainingLocation,
  startDate,
  endDate,
  countryID
) =>
  APIForVolunteerV3V2.get(
    `vln_course_insert?volunteerID=${volunteerID}&courseID=${courseID}&trainingLocation=${trainingLocation}&startDate=${startDate}&endDate=${endDate}&countryID=${countryID}`
  );
export const getVolunteerLanguageDetails = (langNumber, volunteerID) =>
  APIForVolunteerV3V2.get(
    `volunteer_languages?language=${langNumber}&volunteerID=${volunteerID}`
  );
export const addVolunteerLanguage = (langNumber, volunteerID, level) =>
  APIForVolunteerV3V2.get(
    `vln_language_insert?languageID=${langNumber}&volunteerID=${volunteerID}&level=${level}`
  );
export const getAllLanguages = (langNumber) =>
  APIForVolunteerV3V2.get(`language_lookup?language=${langNumber}`);
export const getAllLevels = (langNumber) =>
  APIForVolunteerV3V2.get(`level_lookup?language=${langNumber}`);

export const getVolunteerAbilities = (volunteerID, langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_abilities?language=${langNumber}&volunteerID=${volunteerID}`
  );
export const getAbilitesLookup = (langNumber) =>
  APIForVolunteerV3V2.get(`abilities_lookup?language=${langNumber}`);
export const addVolunteerAbilities = (abilityID, volunteerID, remarks) =>
  APIForVolunteerV3V2.get(
    `vln_abilities_insert?abilityID=${abilityID}&volunteerID=${volunteerID}&remarks=${remarks}`
  );

export const getAllPrograms = (langNumber) =>
  APIForVolunteerV3V2.get(`vln_program_lookup?language=${langNumber}`);
export const getVolunteerPrograms = (volunteerID, langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_programs?volunteerID=${volunteerID}&language=${langNumber}`
  );
export const addVolunteerPrograms = (programID, volunteerID) =>
  APIForVolunteerV3V2.get(
    `vln_program_insert?programID=${programID}&volunteerID=${volunteerID}`
  );

export const getVolunteerEvents = (volunteerID, langNumber) =>
  APIForVolunteerV3V2.get(
    `volunteer_events?volunteerID=${volunteerID}&language=${langNumber}`
  );
export const getVolunteerEventDetails = (
  langNumber,
  volunteerID,
  eventID,
  eventYear
) =>
  APIForVolunteerV3V2.get(
    `volunteer_event_details?language=${langNumber}&volunteerID=${volunteerID}&eventID=${eventID}&eventYear=${eventYear}`
  );

export const getAllEServices = (langNumber, volunteerID) =>
  APIForVolunteerV3V2.get(
    `volunteer_services_new?language=${langNumber}&volunteerID=${volunteerID}`
  );

export const getBranchDetails = (langString) =>
  APIForVolunteerV3V2.get(`workplace_lookup?language=${langString}`);

export const createVLNAccount = (
  arabNAme,
  engNAme,
  mobNumber,
  email,
  eid,
  password,
  workSpaceID,
  gender,
  DOB,
  langToConatct,
  nationality,
  job,
  placeOfWork,
  whatsapp,
  maritalStatus
) =>
  axios.get(
    `${baseURL}/volunteer_create?volunteerNameArabic=${arabNAme}&volunteerNameEnglish=${engNAme}&mobile=${mobNumber}&email=${email}&eid=${eid}&password=${password}&workplaceID=${workSpaceID}&gender=${gender}&dateOfBirth=${DOB}&contactLanguage=${langToConatct}&nationality=${nationality}&job=${job}&placeOfWork=${placeOfWork}&whatsapp=${whatsapp}&maritalStatus=${maritalStatus}`
  );
export const uploadVolunteerProfilePic = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_pic`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });

export const uploadVolunteerIntroduction = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_introduction`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
export const uploadVolunteerJobDetails = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_job`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
export const uploadVolunteereduDetails = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_edu`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
export const uploadVolunteerNathDetails = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_eid2`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
export const uploadVolunteerResiDetails = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_residence`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
export const uploadVolunteerPassDetails = (userData) =>
  axios({
    method: 'post',
    url: `${baseURL}/upload_volunteer_passport`,
    mode: 'no-cors',
    headers: {},
    data: userData
  });
export const checkingUserRegisterStepss = (vlnID) =>
  APIForVolunteerV3V2.get(`check_volunteer_progress?volunteerID=${vlnID}`);
export const linkingVlnAccount = (email, vlnEID) =>
  APIForVolunteerV3V2.get(
    `volunteer_check_link?account_link_email=${email}&volunteer_id=${vlnEID}`
  );
export const sendingOTPForVln = (email, vlnID) =>
  APIForVolunteerV3V2.get(
    `volunteer_link_send_otp?account_link_email=${email}&volunteer_id=${vlnID}&otp_type=MOBILE`
  );
export const confirmLinking = (email, vlnID, otp) =>
  APIForVolunteerV3V2.get(
    `confirm_otp_volunteer_link?account_link_email=${email}&volunteer_id=${vlnID}&otp=${otp}`
  );

export const getMarriage = (lang) =>
  APIForVolunteerV3V2.get(`marriage_lookup?language=${lang}`);
export const getJobs = (lang) =>
  APIForVolunteerV3V2.get(`job_lookup?language=${lang}`);
export const getJobsNew = (lang) =>
  APIForVolunteerV3V2.get(`volunteer_job_list?language=${lang}`);
export const getHealthStatus = (lang) =>
  APIForVolunteerV3V2.get(`health_lookup?language=${lang}`);
export const getRelationshipStatus = (lang) =>
  APIForVolunteerV3V2.get(`relation_lookup?language=${lang}`);
//---------------------------------------------------------------------
export const signInWithSocial = (email, socialID, username, login_type) =>
  axios.get(
    `${baseURL}/SocialAccountSignIn?email=${email}&socialID=${socialID}&username=${username}&login_type=${login_type}`
  );

export const signInWithGoogle1 = async (token) => {
  let response = await axios.get(
    `${baseURL}/google_sign_in?access_token=${token}`
  );
  const jwtToken = response.data.data;
  console.log('JWT Token:', jwtToken);
  if (!jwtToken) throw new Error('Invalid username or password');
  localStorage.setItem('access_token', jwtToken);
  return await whoAmI();
};

export const signInWithUAEPASS = (code, state, redirect_uri) =>
  axios.get(
    `${baseURL}/uae_pass_auth?code=${code}&state=${state}&redirect_uri=${redirect_uri}`
  );
export const signInWithUAEPASS_Setup = (data) =>
  axios({
    url: `${baseURL}/uae_pass_auth_setup`,
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  });
export const signInWithUAEPASS_Setup_OTP = (data) =>
  axios({
    url: `${baseURL}/verify_uae_otp`,
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  });
export const getFacebookEmail = (access_token) =>
  axios.get(
    `https://graph.facebook.com/v12.0/me?fields=id%2Cemail%2Cname&access_token=${access_token}`
  );

export const linkLocalAidRequest = (formData) =>
  axios.get(
    `${baseURL}/link_local_aid_request?account_link_email=${formData.account_link_email}&local_aid_EID=${formData.eid}`,
    formData
  );

export const localAidSendOtp = (email, personId) =>
  axios.get(
    `${baseURL}/local_aid_send_otp?account_link_email=${email}&person_id=${personId}&otp_type=MOBILE`
  );
export const confirmOtpLocalAidLink = (email, personId, otp) =>
  axios.get(
    `${baseURL}/confirm_otp_local_aid_link?account_link_email=${email}&person_id=${personId}&otp=${otp}`
  );
export const sms = (dontypeId) =>
  axios({
    url: `${baseURL}/SMS_DONATION`,
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: new URLSearchParams({ DONTYPE_ID: dontypeId })
  });
export const smsHuman = (caseID) =>
  axios({
    url: `${baseURL}/SMS_DONATION_HUMAN`,
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: new URLSearchParams({ case_id: caseID })
  });

export const getFinalReciept = (or, lng) =>
  axios.get(`${baseURL}/receipt?mco=${or}&language=${lng}`);
// ==========Parteners==============
export const getParteners = (lng) =>
  axios.get(`${baseURL}/get_partners_list?language=${lng}`);
// ============Address type===========
export const getAddressTypes = (language) =>
  axios.get(`${baseURL}/local_aid_address_status?language=${language}`);

export const RemoveAllFromBag = (userID, language) =>
  axios.get(`${baseURL}/remove_cart_all?userID=${userID}&language=${language}`);

// ============Get emirates===========
export const getEmirates = (language) =>
  axios.get(`${baseURL}/emirate_location?language=${language}`);

export const uploadLocalAidFiles = (data) =>
  axios.post(`${baseURL}/local_aid_file_attachment`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const reSubmitReturnedLocalAid = (data) =>
  axios.post(`${baseURL}/update_returned_request`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const submitLocalAidRequest = (data) =>
  axios.post(`${baseURL}/aid_request`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

//============Favorites=============
export const getFavorites = (userID, language) =>
  axios.get(
    `${baseURL}/get_favorites_new?userID=${userID}&language=${language}`
  );
export const deleteFavorites = (email, favoriteID, language) =>
  axios.get(
    `${baseURL}/delete_favorite?email=${email}&favoriteID=${favoriteID}&language=${language}`
  );
export const addFavorites = (itemData) =>
  axios({
    method: 'post',
    url: `${baseURL}/add_favorites`,
    mode: 'no-cors',
    headers: {},
    data: itemData
  });

export const getAllGraceList = (langNumber) =>
  APIForVolunteerV3V2.get(`grace_list?language=${langNumber}`);

export const graceRequest = (query) =>
  axios.get(`${baseURL}/grace_request${query}`);

export const graceCost = (query) => axios.get(`${baseURL}/grace_cost`);

export const getLocalAIDData = (req_id, req_year, language) =>
  axios.get(
    `${baseURL}/get_aid_details?req_id=${req_id}&req_year=${req_year}&language=${language}`
  );

export const enrollEvent = (event_id, event_year, vln_id) =>
  axios({
    url: `${baseURL}/enroll_event`,
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      eventDetails: {
        event_id: event_id,
        event_year: event_year,
        vln_id: vln_id
      }
    }
  });

export const createPaymentSession = (data) =>
  axios({
    method: 'post',
    url: `${baseURL}/create_payment_session`,
    mode: 'no-cors',
    headers: {},
    data: { data: data }
  });

export const encryptPaymentData = async (data) => {
  return await axios({
    method: 'post',
    url: `${baseURL}/encrypt_data`,
    mode: 'no-cors',
    headers: {},
    data: { data: data }
  });
};

export const decryptPaymentData = (data) =>
  axios({
    method: 'post',
    url: `${baseURL}/decrypt_data`,
    mode: 'no-cors',
    headers: {},
    data: { data: data }
  });

export const checkGraceOption = () => axios.get(`${baseURL}/grace_pay_option`);
export const showAdvertisement = (data) =>
  axios({
    method: 'POST',
    url: `${baseURL}/show_advertisement`,
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: new URLSearchParams(data)
  });

export const getShareProjects = (langNumber) =>
  axios.get(`${baseURL}/project_sharing_list?language=${langNumber}`);

export const getProjectPDF = (sponserID, project_id, project_year, report_id) =>
  axios({
    method: 'GET',
    url: `${baseURL}/project_report_pdf?sponser_id=${sponserID}&project_id=${project_id}&project_year=${project_year}&report_id=${report_id}`,
    mode: 'no-cors',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/pdf',
      'Content-Transfer-Encoding': 'binary',
      'Accept-Ranges': 'bytes'
    }
  });

// export const readAttachment = (filePath) =>
// axios.get(`${baseURL}/read_attachment?&filePath=${filePath}`);
export const getProjectAttachment = (project_id, project_year) =>
  axios.get(
    `${baseURL}/get_project_attachments?&project_id=${project_id}&project_year=${project_year}`
  );
export const getProjectYears = (project_id) =>
  axios.get(`${baseURL}/get_project_report_years?project_id=${project_id}`);

export const getVolunteerCoursePDF = (volunteerID, courseID, language) =>
  axios({
    method: 'GET',
    url: `${baseURL}/volunteer_certificate_pdf?volunteerID=${volunteerID}&courseID=${courseID}&language=${language}`,
    mode: 'no-cors',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/pdf',
      'Content-Transfer-Encoding': 'binary',
      'Accept-Ranges': 'bytes'
    }
  });

export const readAttachment = async (filePath) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${baseURL}/read_attachment`,
      params: {
        filePath: filePath
      },
      responseType: 'blob', // Handle binary data like files
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/octet-stream'
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error reading attachment:', error);
    throw error;
  }
};

export const getVolunteerTerms = (langNumber) =>
  axios.get(`${baseURL}/volunteer_terms_document?language=${langNumber}`);

export const getVolunteerIntro = (langNumber) =>
  axios.get(`${baseURL}/volunteer_introduction_pdf?language=${langNumber}`);

export const getProjectShareSingle = (req_id, req_year, langNumber) =>
  axios.get(
    `${baseURL}/project_sharing_single?req_id=${req_id}&req_year=${req_year}&language=${langNumber}`
  );

export const getLandingPages = (langNumber) =>
  // axios.get(`${baseURL}/landing_pages_list?language=${langNumber}`);
  axios.get(`${baseURL}/GetLandPage?language=${langNumber}`);

//course

export const paid_courses_list = ({ filter, language }) =>
  axios.get(
    `${baseURL}/paid_courses_list?filter=${filter}&language=${language}`
  );

//donate with realestate
export const donateRealestate = async (data) => {
  return await axios({
    method: 'post',
    url: `${baseURL}/create_real_estate`,
    mode: 'no-cors',
    headers: {},
    data: data
  });
};
export const donateIdea = async (data) => {
  return await axios({
    method: 'post',
    url: `${baseURL}/InsertIdeaDon`,
    mode: 'no-cors',
    headers: {},
    data: data
  });
};

export const getCountryCode = (language) =>
  axios.get(`${baseURL}/GetCountries?language=${language}`);
export const getCommunityAww = (language = 1) =>
  axios.get(`${baseURL}/GetAwrAtt?language=${language}`);
